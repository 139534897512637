import { useEffect, useState } from 'react'
import { ApplicationConfigurationDto } from '../../api/types'
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Tooltip, Typography } from '@mui/material'
import { ECERT_API } from '../../services/ecert-api'
import { useTranslation } from 'react-i18next'
import React from 'react'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { EcertDatePicker } from '../../features/forms/date-picker/EcertDatePicker'
import StyleGuide from '../../styles/StyleGuide'
import CheckIcon from '@mui/icons-material/Check'
import { parseDate } from '../../utils/utils'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../features/custom-snackbar-provider/CustomSnackbarProvider'
import CustomDialog from '../../features/custom-dialog/CustomDialog'
import ConfirmButton from '../../features/buttons/ConfirmButton'
import CancelButton from '../../features/buttons/CancelButton'

enum ApplicationConfigurationProperties {
  INCOMING_EXTERNAL_TRANSFERS_ALLOWED = 'incomingExternalTransfersAllowed',
  OUTGOING_EXTERNAL_TRANSFERS_ALLOWED = 'outgoingExternalTransfersAllowed',
  INCOMING_EXTERNAL_TRANSFERS_BLOCKED = 'incomingExternalTransfersBlocked',
  CANCELLATIONS_TO_AIB_COUNTRIES_ALLOWED = 'cancellationsToAibCountriesAllowed'
}

export default function Settings() {
  const [configurations, setConfigurations] = useState<ApplicationConfigurationDto[]>()
  const [isModalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    ECERT_API.getConfigurations().then((response) => {
      setConfigurations(response.data)
    })

    document.title = `Ecert - ${t('title.appConfig')}`

    //eslint-disable-next-line
  }, [])

  const findPropertyFromConfigurations = (
    configurations: ApplicationConfigurationDto[],
    property: ApplicationConfigurationProperties
  ) => {
    return configurations.find((configuration) => configuration.property === property)
  }

  const onChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    configuration: ApplicationConfigurationDto | undefined
  ) => {
    if (configuration)
      setConfigurations(
        (prevState) =>
          prevState?.map((el) =>
            el.property === configuration.property ? { ...configuration, propertyActive: event.target.checked } : el
          )
      )
  }

  const onChangeDate = (date) => {
    setConfigurations(
      (prevState) =>
        prevState?.map((el) =>
          el.property === ApplicationConfigurationProperties.INCOMING_EXTERNAL_TRANSFERS_BLOCKED
            ? {
                ...el,
                propertyDate: parseDate(date).isValid() ? parseDate(date).format('YYYY-MM-DD') : undefined
              }
            : el
        )
    )
  }

  const Submit = () => {
    if (configurations)
      ECERT_API.setConfigurations(configurations).then(() => {
        enqueueSnackbar(t('form.valid.appConfig.updated'), snacky.successOpts)
        setModalOpen(false)
      })
  }

  return (
    <>
      {configurations && (
        <Box sx={{ flexDirection: 'column' }}>
          <Typography variant="h1" sx={{ marginBottom: '18px' }}>
            {t('appConfig.title')}
          </Typography>
          <FormGroup>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) =>
                      onChangeCheckbox(
                        e,
                        findPropertyFromConfigurations(
                          configurations,
                          ApplicationConfigurationProperties.INCOMING_EXTERNAL_TRANSFERS_ALLOWED
                        )
                      )
                    }
                    checked={
                      findPropertyFromConfigurations(
                        configurations,
                        ApplicationConfigurationProperties.INCOMING_EXTERNAL_TRANSFERS_ALLOWED
                      )?.propertyActive
                    }
                  />
                }
                label={t('appConfig.incomingExternalTransfersAllowed.name')}
              />
              <Tooltip title={t('appConfig.incomingExternalTransfersAllowed.info')}>
                <HelpOutlineOutlinedIcon
                  sx={{
                    color: StyleGuide.constants.COLOR_BRAND,
                    fontSize: '2rem'
                  }}
                />
              </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) =>
                      onChangeCheckbox(
                        e,
                        findPropertyFromConfigurations(
                          configurations,
                          ApplicationConfigurationProperties.OUTGOING_EXTERNAL_TRANSFERS_ALLOWED
                        )
                      )
                    }
                    checked={
                      findPropertyFromConfigurations(
                        configurations,
                        ApplicationConfigurationProperties.OUTGOING_EXTERNAL_TRANSFERS_ALLOWED
                      )?.propertyActive
                    }
                  />
                }
                label={t('appConfig.outgoingExternalTransfersAllowed.name')}
              />
              <Tooltip title={t('appConfig.outgoingExternalTransfersAllowed.info')}>
                <HelpOutlineOutlinedIcon
                  sx={{
                    color: StyleGuide.constants.COLOR_BRAND,
                    fontSize: '2rem'
                  }}
                />
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px'
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) =>
                      onChangeCheckbox(
                        e,
                        findPropertyFromConfigurations(
                          configurations,
                          ApplicationConfigurationProperties.CANCELLATIONS_TO_AIB_COUNTRIES_ALLOWED
                        )
                      )
                    }
                    checked={
                      findPropertyFromConfigurations(
                        configurations,
                        ApplicationConfigurationProperties.CANCELLATIONS_TO_AIB_COUNTRIES_ALLOWED
                      )?.propertyActive
                    }
                  />
                }
                label={t('appConfig.cancellationsToAibCountriesAllowed.name')}
              />
              <Tooltip title={t('appConfig.cancellationsToAibCountriesAllowed.info')}>
                <HelpOutlineOutlinedIcon
                  sx={{
                    color: StyleGuide.constants.COLOR_BRAND,
                    fontSize: '2rem'
                  }}
                />
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '450px'
              }}
            >
              <Typography variant="label" sx={{ marginBottom: '4px' }}>
                {t('appConfig.incomingExternalTransfersBlocked.name')}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <EcertDatePicker
                  name="date"
                  onUpdate={onChangeDate}
                  defaultValue={
                    findPropertyFromConfigurations(
                      configurations,
                      ApplicationConfigurationProperties.INCOMING_EXTERNAL_TRANSFERS_BLOCKED
                    )?.propertyDate
                  }
                />
                <Tooltip title={t('appConfig.incomingExternalTransfersBlocked.info')}>
                  <HelpOutlineOutlinedIcon
                    sx={{
                      color: StyleGuide.constants.COLOR_BRAND,
                      fontSize: '2rem'
                    }}
                  />
                </Tooltip>
              </Box>
            </Box>
          </FormGroup>
          <Box sx={{ marginTop: '16px' }}>
            <Button variant="contained" startIcon={<CheckIcon />} onClick={() => setModalOpen(true)}>
              {t('appConfig.save')}
            </Button>
          </Box>
        </Box>
      )}
      <CustomDialog
        isOpen={isModalOpen}
        title={t('appConfig.confirmDialog.title')}
        handleClose={() => setModalOpen(false)}
        buttons={
          <>
            <ConfirmButton handleConfirm={Submit} />
            <CancelButton handleCancel={() => setModalOpen(false)} />
          </>
        }
      >
        <Typography variant="body1">{t('appConfig.confirmDialog.message')}</Typography>
      </CustomDialog>
    </>
  )
}
